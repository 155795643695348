import { useState } from "react";
import { cn } from "utils/cn";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useMutation } from "@tanstack/react-query";
import { createArticle } from "services/articles";
import { useNavigate } from "react-router-dom";

export const CreateArticle = ({
  articleQuestionData,
}: {
  articleQuestionData: ArticleQuestion;
}) => {
  const [languageTab, setLanguageTab] = useState<Language>("en");
  const [englishContent, setEnglishContent] = useState("");
  const [frenchContent, setFrenchContent] = useState("");
  const [spanishContent, setSpanishContent] = useState("");

  const isCreateArticleButtonActive =
    englishContent.trim().length > 0 &&
    frenchContent.trim().length > 0 &&
    spanishContent.trim().length > 0;

  const navigate = useNavigate();

  const createArticleAction = useMutation({
    mutationFn: createArticle,
    onSuccess: () => {
      navigate("/view-articles");
    },
  });

  return (
    <div className="w-full h-full flex flex-col items-start gap-4">
      <h5>Add article content to "{articleQuestionData.questionText}"</h5>
      <div className="flex items-start border-b border-gray-200 h-10">
        <button
          onClick={() => setLanguageTab("en")}
          className={cn("h-full px-6 flex items-center justify-center", {
            "border-b-2 border-gray-800": languageTab === "en",
          })}
        >
          English
        </button>
        <button
          onClick={() => setLanguageTab("fr")}
          className={cn("h-full px-6 flex items-center justify-center", {
            "border-b-2 border-gray-800": languageTab === "fr",
          })}
        >
          French
        </button>

        <button
          onClick={() => setLanguageTab("es")}
          className={cn("h-full px-6 flex items-center justify-center", {
            "border-b-2 border-gray-800": languageTab === "es",
          })}
        >
          Spanish
        </button>
      </div>
      {languageTab === "en" ? (
        <div className="w-full h-64 my-2">
          <ReactQuill
            theme="snow"
            value={englishContent}
            onChange={(value) => {
              setEnglishContent(value);
            }}
            className="w-full h-full"
          />
        </div>
      ) : languageTab === "fr" ? (
        <div className="w-full h-64 my-2">
          <ReactQuill
            theme="snow"
            value={frenchContent}
            onChange={(value) => {
              setFrenchContent(value);
            }}
            className="w-full h-full"
          />
        </div>
      ) : (
        <div className="w-full h-64 my-2">
          <ReactQuill
            theme="snow"
            value={spanishContent}
            onChange={(value) => {
              setSpanishContent(value);
            }}
            className="w-full h-full"
          />
        </div>
      )}
      <div className="w-full flex justify-end mt-8">
        <button
          className="flex items-center justify-center px-4 py-2 rounded-md bg-blue-700 hover:bg-blue-800 text-blue-50 font-semibold text-sm disabled:bg-opacity-50 disabled:cursor-not-allowed"
          disabled={
            !isCreateArticleButtonActive || createArticleAction.isPending
          }
          onClick={async () => {
            await createArticleAction.mutateAsync({
              question_id: articleQuestionData.questionId,
              category_id: articleQuestionData.categoryId,
              topic_id: articleQuestionData.topicId,
              content: [
                { language_code: "en", article: englishContent },
                { language_code: "fr", article: frenchContent },
                { language_code: "es", article: spanishContent },
              ],
            });
          }}
        >
          Create Article
        </button>
      </div>
    </div>
  );
};
