import { useMutation, useQuery } from "@tanstack/react-query";
import { TextInput } from "Common/Components/form/input";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import {
  createQuestion,
  getCategories,
  getTopicsByCategoryId,
} from "services/articles";

export const CreateQuestion = ({
  updateStep,
  onSendArticleQuestionData,
}: {
  updateStep: Dispatch<SetStateAction<number>>;
  onSendArticleQuestionData: (data: ArticleQuestion) => void;
}) => {
  const [categoryId, setCategoryId] = useState<ID | null>(null);
  const [topicId, setTopicId] = useState<ID | null>(null);

  const { data: categories } = useQuery({
    queryKey: ["articleCategories"],
    queryFn: getCategories,
    staleTime: Infinity,
  });
  const { data: topics } = useQuery({
    queryKey: ["articles.topics", categoryId],
    queryFn: () => getTopicsByCategoryId(categoryId as number),
    enabled: !!categoryId,
  });

  const createQuestionAction = useMutation({
    mutationFn: createQuestion,
    onSuccess: (res) => {
      onSendArticleQuestionData({
        questionText: res.translation.question,
        questionId: res.translation.question_id,
        categoryId: res.translation.category_id,
        topicId: res.translation.topic_id,
      });
      updateStep(2);
    },
  });

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const en_question = (formData.get("en_question") as string) || "";
    const fr_question = (formData.get("fr_question") as string) || "";
    const es_question = (formData.get("es_question") as string) || "";

    const questionData: CreateQuestionData = {
      category_id: categoryId as number,
      topic_id: topicId as number,
      content: [
        { language_code: "en", question: en_question },
        { language_code: "fr", question: fr_question },
        { language_code: "es", question: es_question },
      ],
    };

    await createQuestionAction.mutateAsync(questionData);
  };

  return (
    <div className="w-full flex flex-col items-start gap-4">
      <h5>Create Question</h5>
      <div className="flex flex-col w-full items-start gap-6">
        <div className="flex items-start gap-4">
          <div className="flex flex-col items-start gap-2">
            <label htmlFor="category" className="text-sm font-semibold">
              Select category
            </label>
            <select
              onChange={(e) =>
                setCategoryId(+e.target.value !== 0 ? +e.target.value : null)
              }
              id="category"
              className="w-64 p-2 border border-gray-200 bg-gray-100 text-gray-800 rounded disabled:cursor-not-allowed disabled:opacity-50"
            >
              <option value={0}>Select Category</option>
              {categories?.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.translation.title}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col items-start gap-2">
            <label htmlFor="topic" className="text-sm font-semibold">
              Select topic
            </label>
            <select
              onChange={(e) =>
                setTopicId(+e.target.value !== 0 ? +e.target.value : null)
              }
              className="w-64 p-2 border border-gray-200 bg-gray-100 text-gray-800 rounded disabled:cursor-not-allowed disabled:opacity-50"
              id="topic"
              disabled={categoryId === 0 || categoryId === null}
            >
              <option value={0}>Select Topic</option>
              {topics?.map((topic) => (
                <option key={topic.id} value={topic.id}>
                  {topic.translation.title}
                </option>
              ))}
            </select>
          </div>
        </div>
        {!topicId ? (
          <p>Please select a topic to continue</p>
        ) : (
          <form
            onSubmit={handleSubmit}
            className="w-1/2 flex flex-col items-start gap-3"
          >
            <TextInput
              name="en_question"
              label="Question (English)"
              placeholder="Enter question in English"
            />
            <TextInput
              name="es_question"
              label="Question (Spanish)"
              placeholder="Enter question in Spanish"
            />
            <TextInput
              name="fr_question"
              label="Question (French)"
              placeholder="Enter question in French"
            />
            <button
              type="submit"
              className="p-2 px-4 rounded text-white font-semibold text-base bg-blue-500 hover:bg-blue-800"
            >
              Create Question
            </button>
          </form>
        )}
      </div>
    </div>
  );
};
