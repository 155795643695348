export const baseUrl = "https://nl.sharewordglobal.dev/admin/v1";

export const apis = {
  category: {
    all: `${baseUrl}/category/all`,
    singleCategory: (id: string) => `${baseUrl}/category/${id}/get`,
    create: `${baseUrl}/category/create`,
    update: `${baseUrl}/category/update`,
    delete: (id: number) => `${baseUrl}/category/${id}/delete`,
    uploadImage: `${baseUrl}/category/upload-image`,
  },
  topic: {
    all: `${baseUrl}/topic/all`,
    singleTopic: (id: string) => `${baseUrl}/topic/${id}/get`,
    getByCategory: (id: number) => `${baseUrl}/topic/${id}/category`,
    create: `${baseUrl}/topic/create`,
    update: `${baseUrl}/topic/update`,
    delete: (id: number) => `${baseUrl}/topic/${id}/delete`,
  },
  question: {
    getByTopic: (id: number) => `${baseUrl}/question/${id}/topic`,
    create: `${baseUrl}/question/create`,
    edit: `${baseUrl}/question/update`,
    delete: (questionId: number) => `${baseUrl}/question/${questionId}/delete`,
  },
  article: {
    getBySlug: (slug: string) => `${baseUrl}/question/${slug}/slug`,
    create: `${baseUrl}/article/create`,
    delete: (articleId: number) => `${baseUrl}/article/${articleId}/delete`,
    update: `${baseUrl}/article/update`,
  },
};
