import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TextInput } from "Common/Components/form/input";
import { Modal } from "Common/Components/modal-2";
import { FormEvent } from "react";
import { editQuestion } from "services/articles";

export const EditQuestion = ({
  open,
  close,
  question,
  question_id,
  category_id,
  topic_id,
}: {
  open: boolean;
  close: VoidFunction;
  question: {
    en: string;
    fr: string;
    es: string;
  };
  question_id: number;
  category_id: number;
  topic_id: number;
}) => {
  const queryClient = useQueryClient();
  const editQuestionAction = useMutation({
    mutationFn: editQuestion,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["topics.questions", topic_id],
      });
      close();
    },
  });
  const handleEditQuestion = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const en_question = (formData.get("en_question") as string) || "";
    const fr_question = (formData.get("fr_question") as string) || "";
    const es_question = (formData.get("es_question") as string) || "";

    await editQuestionAction.mutateAsync({
      topic_id,
      question_id,
      category_id,
      content: [
        {
          language_code: "en",
          question: en_question,
        },
        {
          language_code: "fr",
          question: fr_question,
        },
        {
          language_code: "es",
          question: es_question,
        },
      ],
    });
  };
  return (
    <Modal open={open} close={close}>
      <Modal.Header>
        <Modal.Title>Edit "{question.en}"</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={handleEditQuestion}
          className="w-full flex flex-col items-start gap-4"
        >
          <TextInput
            label="Question (English)"
            name="en_question"
            defaultValue={question.en}
          />
          <TextInput
            label="Question (Spanish)"
            name="es_question"
            defaultValue={question.es}
          />
          <TextInput
            label="Question (French)"
            name="fr_question"
            defaultValue={question.fr}
          />
          <div className="w-full flex justify-end items-start gap-2">
            <button
              onClick={close}
              className="p-2 px-4 rounded text-white font-semibold text-base bg-red-500 hover:bg-red-800"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="p-2 px-4 rounded text-white font-semibold text-base bg-blue-500 hover:bg-blue-800 disabled:bg-opacity-50"
              disabled={editQuestionAction.isPending}
            >
              Save Changes
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
