import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  deleteArticle,
  getArticlesByQuestionSlug,
  updateArticle,
} from "services/articles";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect, useState } from "react";
import { cn } from "utils/cn";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";

export default function SingleArticle() {
  const { articleSlug } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [languageTab, setLanguageTab] = useState<Language>("en");
  const [enContent, setEnContent] = useState<string>("");
  const [frContent, setFrContent] = useState<string>("");
  const [esContent, setEsContent] = useState<string>("");

  const { data: article, isLoading } = useQuery({
    queryKey: ["article", articleSlug],
    queryFn: () => getArticlesByQuestionSlug(articleSlug as string),
    enabled: !!articleSlug,
    staleTime: Infinity,
  });
  const editArticleAction = useMutation({
    mutationFn: updateArticle,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["article", articleSlug] });
    },
  });
  const deleteArticleAction = useMutation({
    mutationFn: deleteArticle,
    onSuccess: () => {
      navigate("/view-articles");
    },
  });

  useEffect(() => {
    if (article) {
      const enArticleContent =
        article.translation.articles.find((art) => art.language_code === "en")
          ?.article || "";
      const esArticleContent =
        article.translation.articles.find((art) => art.language_code === "es")
          ?.article || "";
      const frArticleContent =
        article.translation.articles.find((art) => art.language_code === "fr")
          ?.article || "";

      setEnContent(enArticleContent);
      setEsContent(esArticleContent);
      setFrContent(frArticleContent);
    }
  }, [article]);

  if (isLoading) {
    return (
      <div className="w-full bg-white flex flex-col">fetching article...</div>
    );
  }

  if (!article) {
    return (
      <div className="w-full bg-white flex flex-col items-center p-6">
        <h4>Article of slug: {articleSlug} not found.</h4>
        <Link to={"/view-articles"}>Back Home</Link>
      </div>
    );
  }

  return (
    <div className="w-full bg-white flex flex-col p-6 gap-8">
      <div className="w-full flex items-end justify-between">
        <div className="flex items-center gap-2">
          <Link to={"/view-articles"}>
            <ChevronLeftIcon className="size-6 text-gray-800 hover:text-gray-900 cursor-pointer" />
          </Link>
          <h3>{article.translation.question}</h3>
        </div>
        {article.has_article === 1 ? (
          <button
            className="px-6 py-2 flex items-center justify-center text-base font-semibold bg-red-800 hover:bg-red-900 text-red-50 rounded-md disabled:bg-opacity-50"
            disabled={deleteArticleAction.isPending}
            onClick={async () => {
              await deleteArticleAction.mutateAsync(
                article.translation.article[0].article_id,
              );
            }}
          >
            Delete article
          </button>
        ) : (
          <button className="px-6 py-2 flex items-center justify-center text-base font-semibold bg-blue-600 hover:bg-blue-800 text-blue-50 rounded-md">
            Create article
          </button>
        )}
      </div>
      <div className="w-full flex flex-col items-start gap-2">
        {article.has_article === 0 ? (
          <p>Question has no article. Create one</p>
        ) : null}

        <div className="flex items-start border-b border-gray-200">
          <button
            className={cn("pb-2 px-4", {
              "border-b-2 border-blue-700": languageTab === "en",
            })}
            onClick={() => setLanguageTab("en")}
          >
            English
          </button>
          <button
            className={cn("pb-2 px-4", {
              "border-b-2 border-blue-700": languageTab === "es",
            })}
            onClick={() => setLanguageTab("es")}
          >
            Spanish
          </button>
          <button
            className={cn("pb-2 px-4", {
              "border-b-2 border-blue-700": languageTab === "fr",
            })}
            onClick={() => setLanguageTab("fr")}
          >
            French
          </button>
        </div>

        <div className="w-full flex flex-col items-end gap-4">
          <ReactQuill
            theme="snow"
            value={
              languageTab === "en"
                ? enContent
                : languageTab === "es"
                  ? esContent
                  : frContent
            }
            onChange={(value) => {
              if (languageTab === "en") {
                setEnContent(value);
              } else if (languageTab === "es") {
                setEsContent(value);
              } else if (languageTab === "fr") {
                setFrContent(value);
              }
            }}
          />

          <button
            className="px-4 py-2 rounded bg-blue-700 hover:bg-blue-900 text-blue-50 font-semibold text-sm disabled:bg-opacity-50"
            disabled={editArticleAction.isPending}
            onClick={async () => {
              try {
                await editArticleAction.mutateAsync({
                  category_id: article.translation.category_id,
                  topic_id: article.translation.topic_id,
                  question_id: article.translation.question_id,
                  article_id: article.translation.article[0].article_id,
                  content: [
                    {
                      language_code: "en",
                      article: enContent,
                    },
                    {
                      language_code: "es",
                      article: esContent,
                    },
                    {
                      language_code: "fr",
                      article: frContent,
                    },
                  ],
                });
              } catch (error) {
                console.log(error);
              }
            }}
          >
            Save changes
          </button>
        </div>
      </div>

      <div className="w-full flex flex-col items-start gap-4">
        <h4>Related Articles</h4>
        <ul className="w-full flex flex-col items-start gap-2 list-disc list-inside">
          {article.translation.topic.questions.map((question) => (
            <li key={question.id}>
              <Link to={`/view-articles/${question.slug}`}>
                {question.question}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
