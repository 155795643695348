import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Modal } from "Common/Components/modal-2";
import { deleteQuestion } from "services/articles";

export const DeleteQuestion = ({
  questionId,
  open,
  close,
  topicId,
  question,
}: {
  questionId: number;
  topicId: number;
  open: boolean;
  question: string;
  close: VoidFunction;
}) => {
  const queryClient = useQueryClient();
  const deleteQuestionAction = useMutation({
    mutationFn: deleteQuestion,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["topics.questions", topicId],
      });
      close();
    },
  });
  return (
    <Modal open={open} close={close}>
      <Modal.Header>
        <Modal.Title>Delete {question}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete "{question}"?</p>
        <div className="w-full flex justify-end items-start gap-2">
          <button
            onClick={close}
            className="p-2 px-4 rounded text-white font-semibold text-base bg-blue-500 hover:bg-blue-800"
          >
            No, cancel
          </button>
          <button
            className="p-2 px-4 rounded text-white font-semibold text-base bg-red-500 hover:bg-red-800 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={async () => {
              await deleteQuestionAction.mutateAsync(questionId);
            }}
            disabled={deleteQuestionAction.isPending}
          >
            Yes, delete
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
